<template>
  <div class="w-11/12 flex flex-col justify-center items-center gap-4">
    <sub-head1
      :mainText="'料金について'"
      :subText="'今なら7日間の無料キャンペーン中！'"
    />
    <div class="w-full flex flex-col justify-center items-center">
      <!--説明BOX-->
      <div
        class="w-full border-4 border-fmfm-gray rounded-xl p-4 flex flex-col justify-center items-center gap-4"
      >
        <!--値段-->
        <div class="flex flex-row justify-center items-end">
          <div class="text-3xl md:text-5xl">847</div>
          <div>
            <div class="text-[10px]">(税込み)</div>
            <div class="text-base">円</div>
          </div>
          <div class="text-base">/月会費</div>
        </div>
        <!--全ての機能をご利用いただけます-->
        <div
          class="w-full md:w-4/5 bg-fmfm-accent-blue text-white rounded-md text-center p-2"
        >
          全ての機能をご利用いただけます
        </div>
        <div
          class="w-full md:w-4/5 flex flex-col md:flex-row justify-center md:justify-start items-start md:items-center gap-4"
        >
          <app-icons-view
            :tinder="true"
            :pairs="true"
            :_with="true"
            :tokare="true"
          />
          <div class="text-fmfm-darkgray text-xs md:text-base">
            足あと速度調整、指定した足あと数で止める
          </div>
        </div>
        <div
          class="w-full md:w-4/5 flex flex-col md:flex-row justify-center md:justify-start items-start md:items-center gap-4"
        >
          <app-icons-view
            :tinder="true"
            :pairs="false"
            :_with="false"
            :tokare="false"
          />
          <div class="text-fmfm-darkgray text-xs md:text-baseo overflow-clip">
            スワイプ条件(距離指定、業者排除)、固定メッセージ送信、自動リロード
          </div>
        </div>
      </div>
    </div>

    <!--  プラスアイコン -->
    <fa class="w-8 text-fmfm-accent-pink" :icon="'plus'" />

    <!--足あと無制限-->
    <div class="w-full flex flex-col justify-center items-center">
      <!--説明BOX-->
      <div
        class="relative w-full border-4 border-fmfm-gray rounded-xl p-4 flex flex-col justify-center items-center gap-4"
      >
        <!--足あと無制限-->
        <div class="flex flex-row justify-center items-end">
          <div class="text-3xl md:text-5xl text-fmfm-accent-pink">
            足あと無制限
          </div>
        </div>
        <!--対象アプリ 備考-->
        <div
          class="w-full md:w-4/5 flex flex-col md:flex-row justify-center items-start md:items-center gap-4"
        >
          <app-icons-view
            :tinder="true"
            :pairs="true"
            :_with="true"
            :tokare="true"
          />
          <div class="text-fmfm-darkgray text-xs md:text-base">
            1日50人の制限がなくなります！！
          </div>
        </div>
        <img
          class="absolute right-3 bottom-3 w-1/3 md:w-1/5"
          src="../assets/fmfm_pc.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AppIconsView from "../views/AppIcons.vue";
import SubHead1 from "../components/SubHead1.vue";
export default defineComponent({
  name: "FmfmPrice",
  props: {},
  components: {
    AppIconsView,
    SubHead1,
  },
});
</script>
