<template>
  <div
    class="w-4/5 py-2 md:py-4 text-center text-white md:text-4xl text-xl rounded-2xl shadow-md shadow-fmfm-accent-blue hover:scale-105"
    :class="{
      'bg-fmfm-accent-green': isPremiumUser,
      'bg-fmfm-accent-pink': !isPremiumUser,
    }"
    @click="handleClick"
  >
    {{ value }}
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "FmfmTrialButton",
  methods: {
    handleClick() {
      // クリックイベントを発火し、任意のデータを伴うこともできます
      this.$emit("subscription", "クリックされました");
    },
  },
  props: {
    value: {
      type: String,
      require: true,
      default: "",
    },
    isPremiumUser: {
      type: Boolean,
      require: true,
      default: false,
    },
    isMaintenance: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  components: {},
});
</script>
