
import { defineComponent } from "vue";
import { useStore } from "../store";
import { useRouter } from "vue-router";

import FmfmQuestion from "../components/FmfmQuestion.vue";
import FmfmInstall from "../components/FmfmInstall.vue";
import FmfmCancel from "../components/FmfmCancel.vue";
import FmfmVip from "../components/FmfmVip.vue";
import FmfmFree from "../components/FmfmFree.vue";
//import FmfmReview from "../components/FmfmReview.vue";
import FmfmKuchikomi from "../components/FmfmKuchikomi.vue";
import FmfmPersona from "../components/FmfmPersona.vue";
import FmfmMarketing from "../components/FmfmMarketing.vue";
import FmfmYoutube from "../components/FmfmYoutube.vue";
import FmfmProblem from "../components/FmfmProblem.vue";
import HeaderView from "../views/HeaderView.vue";
import SectionRow from "../components/SectionRow.vue";
import InstallButton from "../components/InstallButton.vue";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const moveToPlan = () => {
      router.push("/plan");
    };
    const moveToSuccess = () => {
      router.push("/success");
    };
    // store.dispatch("getProducts");
    return {
      store,
      moveToPlan,
      moveToSuccess,
    };
  },
  components: {
    HeaderView,
    SectionRow,
    FmfmQuestion,
    FmfmInstall,
    FmfmCancel,
    FmfmVip,
    FmfmFree,
    //FmfmReview,
    FmfmKuchikomi,
    FmfmPersona,
    FmfmMarketing,
    FmfmYoutube,
    FmfmProblem,
    InstallButton,
  },
  data() {
    return {
      isLp: true,
      notSignedInWarning: false,
      bgImage: true,
      selectedService: "Tinder",
      cvText: "今すぐ試してみる",
    };
  },
  async mounted() {
    const token = this.$route.query.t;
    if (token) {
      this.store.dispatch("signin", { token: token });
    }
    const page = this.$route.query.page;
    if (page == "success") this.moveToSuccess();
  },
  computed: {
    isPremiumUser(): boolean {
      return this.store.state.userInfo.isPremiumUser;
    },
    isAuthenticated(): boolean {
      return this.store.state.userInfo.isAuthenticated;
    },
    phraseThree(): string {
      if (this.store.state.userInfo.isTrialingUser) {
        return "あなたは現在トライアル会員です";
      } else if (this.store.state.userInfo.isPremiumUser) {
        return "あなたはVIP会員です";
      } else {
        return "今なら7日間無料！いつでも解約OK！";
      }
    },
    startButtonText() {
      if (this.store.state.userInfo.isPremiumUser) {
        return "支払い情報の確認";
      } else {
        return "無料トライアルを始める";
      }
    },
  },
  methods: {
    signin() {
      this.store.dispatch("signin", { token: undefined });
    },
    async kakin() {
      if (!this.store.state.userInfo.isAuthenticated) {
        this.notSignedInWarning = true;
        console.log(this.notSignedInWarning);
        setTimeout(() => {
          this.notSignedInWarning = false;
        }, 300);
        return;
      }
      this.moveToPlan();
    },
    openChromeExtension() {
      window.open(
        "https://chrome.google.com/webstore/detail/%E3%81%B5%E3%82%80%E3%81%B5%E3%82%80%E5%90%9B/lbcclnedcbmomnihgophjinmkikiidkf?hl=ja"
      );
    },
    openTwitter() {
      window.open("https://twitter.com/fmfm_kun");
    },
  },
});
