
import { defineComponent } from "vue";
import { useStore } from "../store";
import { useRouter } from "vue-router";
import AppIconsView from "./AppIcons.vue";
import HeaderView from "../views/HeaderView.vue";
import SectionRow from "../components/SectionRow.vue";
import FmfmPrice from "../components/FmfmPrice.vue";
//import FmfmFree from "../components/FmfmFree.vue";
import FmfmCancel from "../components/FmfmCancel.vue";
import FmfmTrialButton from "../components/FmfmTrialButton.vue";
import FmfmYoutube from "../components/FmfmYoutube.vue";
import FmfmInstall from "../components/FmfmInstall.vue";
import FmfmQuestion from "../components/FmfmQuestion.vue";
import FmfmFunction from "../components/FmfmFunction.vue";
import { maintenance } from "../utils/maintenance";

export default defineComponent({
  setup() {
    const _maintenance = maintenance;
    const router = useRouter();
    const store = useStore();
    const moveToPlan = () => {
      router.push("/plan");
    };
    const moveToSuccess = () => {
      router.push("/success");
    };
    // store.dispatch("getProducts");
    return {
      store,
      router,
      _maintenance,
      moveToPlan,
      moveToSuccess,
    };
  },
  components: {
    HeaderView,
    AppIconsView,
    SectionRow,
    FmfmPrice,
    FmfmYoutube,
    FmfmCancel,
    FmfmTrialButton,
    FmfmInstall,
    FmfmQuestion,
    FmfmFunction,
  },
  data() {
    return {
      notSignedInWarning: false,
    };
  },
  async mounted() {
    const token = this.$route.query.t;
    if (token) {
      this.store.dispatch("signin", { token: token });
    }
    const page = this.$route.query.page;
    if (page == "success") this.moveToSuccess();

    // chrome拡張からのメッセージを監視する
    window.addEventListener(
      "message",
      (event) => {
        console.log(event);
        if (event?.data?.token) {
          this.store.dispatch("signin", { token: event.data.token });
        }
      },
      false
    );
  },
  computed: {
    isPremiumUser(): boolean {
      return this.store.state.userInfo.isPremiumUser;
    },
    isAuthenticated(): boolean {
      return this.store.state.userInfo.isAuthenticated;
    },
    phraseThree(): string {
      if (this.store.state.userInfo.isTrialingUser) {
        return "あなたは現在トライアル会員です";
      } else if (this.store.state.userInfo.isPremiumUser) {
        return "あなたはVIP会員です";
      } else {
        return "今なら7日間無料！いつでも解約OK！";
      }
    },
    startButtonText() {
      if (maintenance.status) {
        return maintenance.message;
      }
      if (this.store.state.userInfo.isPremiumUser) {
        return "支払い情報の確認";
      } else {
        return "無料トライアルを始める";
      }
    },
  },
  methods: {
    signin() {
      this.store.dispatch("signin", { token: undefined });
    },
    async kakin() {
      if (!this.store.state.userInfo.isAuthenticated) {
        this.notSignedInWarning = true;
        console.log(this.notSignedInWarning);
        setTimeout(() => {
          this.notSignedInWarning = false;
        }, 300);
        return;
      }
      this.moveToPlan();
    },
    subscription() {
      if (maintenance.status) {
        alert(
          "申し訳ございません。ただいまメンテナンス中ですのでしばらくお待ちください。問い合わせはfu360muro@gmail.comまでお願いします。"
        );
        return;
      }
      if (!this.store.state.userInfo.isAuthenticated) {
        window.scroll({ top: 0, behavior: "smooth" });
        this.store.dispatch("showWarning");
        setTimeout(() => {
          this.store.dispatch("hideWarning");
        }, 1000);
        return;
      }
      if (this.isPremiumUser) {
        this.store.dispatch("callBillingPortal");
      } else {
        this.store.dispatch("checkout");
      }
    },
  },
});
