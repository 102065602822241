import { createStore, Store, useStore as baseUseStore } from "vuex";
import { InjectionKey } from "vue";
import {
  Auth,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import {
  Firestore,
  getFirestore,
  onSnapshot,
  query,
} from "@firebase/firestore";
import { firebaseConfig } from "../utils/firebase_config";
import { initializeApp } from "firebase/app";
import {
  isCancancelAtPeriodEnd,
  isPremiumUser,
  isTrialingUser,
} from "@/utils/firebase";
import {
  addDoc,
  collection,
  DocumentData,
  getDocs,
  orderBy,
  where,
} from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import {
  Functions,
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions";
import { stripeConfig } from "@/utils/stripe_config";

export interface UserInfo {
  isAuthenticated: boolean;
  isPremiumUser: boolean;
  isTrialingUser: boolean;
  isCancancelAtPeriodEnd: boolean;
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
}

export interface Product {
  priceId: string;
  src: string;
  priceData: DocumentData;
}

export interface State {
  auth: Auth;
  db: Firestore;
  func: Functions;
  userInfo: UserInfo;
  products: Product[];
  isLoading: boolean;
  isWarning: boolean;
}

interface GetSanitizedMessageResponse extends HttpsCallableResult {
  readonly url: string;
}

export const key: InjectionKey<Store<State>> = Symbol("store");

export const store = createStore<State>({
  state() {
    //const isWarning = false;
    const firebaseApp = initializeApp(firebaseConfig);
    const db = getFirestore(firebaseApp);
    const auth = getAuth(firebaseApp);
    const func = getFunctions(firebaseApp);
    return {
      isWarning: false,
      auth: auth,
      db: db,
      func: func,
      products: [],
      isLoading: false,
      userInfo: {
        isPremiumUser: false,
        isAuthenticated: false,
        isTrialingUser: false,
        uid: "",
        email: "",
        displayName: "",
        photoURL: "",
      } as UserInfo,
    };
  },
  mutations: {
    updateIsLoaging(state, { isLoading }: { isLoading: boolean }) {
      state.isLoading = isLoading;
    },
    updateIsWarning(state, { isWarning }: { isWarning: boolean }) {
      state.isWarning = isWarning;
    },
    updateProducts(state, { products }: { products: Product[] }) {
      if (products.length == 0) {
        state.products = [];
        return;
      }
      state.products.push(...products);
    },
    addOnAuthStateChanged(state) {
      state.auth.onAuthStateChanged(async (user) => {
        if (user) {
          console.log("Status: signin");
          state.userInfo.uid = user.uid ?? "";
          state.userInfo.email = user.email ?? "";
          state.userInfo.photoURL = user.photoURL ?? "";
          state.userInfo.displayName = user.displayName ?? "";
          state.userInfo.isAuthenticated = true;
          state.userInfo.isPremiumUser = await isPremiumUser(
            state.db,
            state.userInfo.uid
          );
          state.userInfo.isTrialingUser = await isTrialingUser(
            state.db,
            state.userInfo.uid
          );
          state.userInfo.isCancancelAtPeriodEnd = await isCancancelAtPeriodEnd(
            state.db,
            state.userInfo.uid
          );
        } else {
          console.log("Status: signout");
          state.userInfo.uid = "";
          state.userInfo.email = "";
          state.userInfo.photoURL = "";
          state.userInfo.displayName = "";
          state.userInfo.isAuthenticated = false;
          state.userInfo.isPremiumUser = false;
          state.userInfo.isTrialingUser = false;
        }
      });
    },
    async signin(state, { token }) {
      //console.log(`signin token = ${token}`);
      if (token) {
        const oAuthCredential = GoogleAuthProvider.credential(null, token);
        const ret = await signInWithCredential(state.auth, oAuthCredential)
          .then((userCredential) => {
            //console.log(userCredential);
            return true;
          })
          .catch((error) => {
            //console.log(error);
            state.auth.signOut();
            return false;
          });
        if (ret) return;
      }
      const provider = new GoogleAuthProvider();
      signInWithPopup(state.auth, provider)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async signout(state) {
      await state.auth.signOut();
    },
  },
  actions: {
    async addOnAuthStateChanged(context) {
      context.commit("addOnAuthStateChanged");
    },
    async signin(context, { token }) {
      context.commit("signin", { token: token });
    },
    showWarning(context) {
      context.commit("updateIsWarning", { isWarning: true });
    },
    hideWarning(context) {
      context.commit("updateIsWarning", { isWarning: false });
    },
    async signout(context) {
      context.commit("signout");
    },
    async callBillingPortal(context) {
      context.commit("updateIsLoaging", { isLoading: true });
      // Call billing portal function
      this.state.func.region = "asia-northeast1";
      const functionRef = httpsCallable<unknown, GetSanitizedMessageResponse>(
        this.state.func,
        "ext-firestore-stripe-payments-createPortalLink"
      );
      const { data } = await functionRef({
        returnUrl: `${window.location.origin}/vip`,
      });
      context.commit("updateIsLoaging", { isLoading: false });
      window.location.assign(data.url);
    },
    async checkout(context) {
      //console.log(stripeConfig.secretKey);
      context.commit("updateIsLoaging", { isLoading: true });
      const colRef = collection(
        this.state.db,
        "customers",
        this.state.userInfo.uid,
        "checkout_sessions"
      );
      const subscRef = collection(
        this.state.db,
        "customers",
        this.state.userInfo.uid,
        "subscriptions"
      );
      const subscDocRef = await getDocs(subscRef);
      const isReRegister = !subscDocRef.empty;
      //console.log({ isReRegister });
      //const docSnap = await getDocs(colRef);
      //console.log(docSnap);
      const taxRates = ["txr_1L4a1lGIUrncLaaaI5Q6RkXo"];
      const line_items = [
        {
          price: "price_1L4a6hGIUrncLaaagtmMf89x", //this.state.products[0].priceId,
          quantity: 1,
        },
      ];
      //console.table(line_items);
      const checkoutSession = {
        collect_shipping_address: true,
        tax_rates: taxRates,
        allow_promotion_codes: true,
        line_items: line_items,
        success_url: `${window.location.origin}?page=success`,
        cancel_url: `${window.location.origin}?page=vip`,
        trial_from_plan: !isReRegister,
      };
      const docRef = await addDoc(colRef, checkoutSession);

      onSnapshot(docRef, async (snap) => {
        const data = snap.data();
        if (data && data.sessionId) {
          //console.log({ sessionId: data.sessionId });
          const stripe = await loadStripe(stripeConfig.publicKey ?? "");
          context.commit("updateIsLoaging", { isLoading: false });
          await stripe?.redirectToCheckout({ sessionId: data.sessionId });
        } else {
          console.log("sessionId is undefined, waiting for update...");
          console.log(data?.error?.message);
        }
      });
    },
    async getProducts(context) {
      context.commit("updateProducts", { products: [] });
      const q = query(
        collection(this.state.db, `/products`),
        where("active", "==", true)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        const q2 = query(
          collection(doc.ref, `/prices`),
          where("active", "==", true),
          orderBy("unit_amount")
        );
        const data = await getDocs(q2);
        const product = doc.data();
        //console.log(data);
        data.docs.forEach((doc, index) => {
          const priceId = doc.id;
          const priceData = doc.data();
          context.commit("updateProducts", {
            products: [
              {
                priceId: priceId,
                src: product.images[index],
                priceData: priceData,
              },
            ],
          });
        });
      });
    },
  },
});

// 定义自己的'useStore'组合函数
export function useStore() {
  return baseUseStore(key);
}
