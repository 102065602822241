<template>
  <div class="w-11/12 flex flex-col">
    <sub-head1
      :mainText="'機能の紹介'"
      :subText="'1つ1つの機能がノウハウの塊です'"
    />
    <!-- STEP1 -->

    <img-and-text
      :title="'足あと速度調整'"
      :img="'fmfm_setting1.png'"
      :numbering="'1'"
      :noBorder="true"
      :texts="[
        {
          value:
            '最小〜最大の幅でランダムに足あと速度を変えることができます。これによりBOT判定を防ぐことができます!',
        },
      ]"
    >
      <div class="w-full pl-2 md:pl-4">
        <app-icons-view
          :tinder="true"
          :pairs="true"
          :_with="true"
          :tokare="true"
        />
      </div>
    </img-and-text>

    <!-- STEP2 -->
    <img-and-text
      :title="'指定した足あと数で止める'"
      :img="'fmfm_setting2.png'"
      :numbering="'2'"
      :noBorder="true"
      :texts="[
        {
          value:
            '起動させっぱなしにしてしまう心配はありません。Pairs、With、東カレは1日1000人程度、Tinderは3000人程度を目安に継続すると効果的です!',
        },
      ]"
      ><div class="w-full pl-2 md:pl-4">
        <app-icons-view
          :tinder="true"
          :pairs="true"
          :_with="true"
          :tokare="true"
        />
      </div>
    </img-and-text>

    <!-- STEP3 -->
    <img-and-text
      :title="'スワイプ条件(距離指定、業者排除)'"
      :img="'tinder_setting1.png'"
      :numbering="'3'"
      :noBorder="true"
      :texts="[
        {
          value: '遠すぎる距離の相手や怪しい業者は左スワイプしましょう!',
        },
      ]"
      ><div class="w-full pl-2 md:pl-4">
        <app-icons-view
          :tinder="true"
          :pairs="false"
          :_with="false"
          :tokare="false"
        />
      </div>
    </img-and-text>

    <!-- STEP4 -->
    <img-and-text
      :title="'固定メッセージ送信'"
      :img="'tinder_setting2.png'"
      :numbering="'4'"
      :noBorder="true"
      :texts="[
        {
          value:
            'マッチした相手に固定メッセージを送信できます。マッチしたまま放置してしまうとアカウントスコアに悪影響があるとか..？',
        },
      ]"
      ><div class="w-full pl-2 md:pl-4">
        <app-icons-view
          :tinder="true"
          :pairs="false"
          :_with="false"
          :tokare="false"
        />
      </div>
    </img-and-text>

    <!-- STEP5 -->
    <img-and-text
      :title="'自動リロード'"
      :img="'tinder_setting2.png'"
      :numbering="'5'"
      :noBorder="true"
      :texts="[
        {
          value:
            'スワイプ対象がいなくなってしまったら自動で画面をリロードして探し続けます!',
        },
      ]"
    >
      <div class="w-full pl-2 md:pl-4">
        <app-icons-view
          :tinder="true"
          :pairs="true"
          :_with="true"
          :tokare="true"
        /></div
    ></img-and-text>

    <!-- STEP6 -->
    <img-and-text
      :title="'自動で「いいね」する'"
      :img="'tokare_setting1.png'"
      :numbering="'6'"
      :noBorder="true"
      :texts="[
        {
          value: '東カレは足あとだけでなく「いいね」も自動化できます！',
        },
      ]"
    >
      <div class="w-full pl-2 md:pl-4">
        <app-icons-view
          :tinder="false"
          :pairs="false"
          :_with="false"
          :tokare="true"
        /></div
    ></img-and-text>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SubHead1 from "../components/SubHead1.vue";
import ImgAndText from "../components/ImgAndText.vue";
import AppIconsView from "../views/AppIcons.vue";
export default defineComponent({
  name: "FmfmFunction",
  props: {},
  data: function () {
    return {
      noBorder: true,
    };
  },
  components: {
    SubHead1,
    ImgAndText,
    AppIconsView,
  },
});
</script>
